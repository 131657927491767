import { ProductID, TelemetryEvent } from "@headbot/library";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "../../atoms/H2/H2";
import { IProduct, PackageSelector } from "../../atoms/PackageSelector/PackageSelector";
import { PaypalProvider } from "../../layout/PaypalProvider/PaypalProvider";
import { WithAuth } from "../../molecules/Auth/WithAuth";
import { FullsizeImage } from "../../molecules/FullsizeImage/FullsizeImage";
import { PaypalPrintPurchase } from "../../molecules/PaypalPrintPurchase /PaypalPrintPurchase ";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { Root } from "./PurchasePrintPage.styles";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { telemetry } from "../../../services/Telemetry/Telemetry";

interface IProps {
    readonly jobId: string;
    readonly imageId: string;
}

export const PurchasePrintPage: React.FC<React.PropsWithChildren<IProps>> = ({ imageId, jobId }) => {
    const [t] = useTranslation();
    const description = t("purchasePrintPage.description");
    const title = t("purchasePrintPage.title");
    const [chosenProductID, setChosenProductID] = useState<ProductID | null>(null);

    if (typeof window !== "undefined") {
        useEffect(() => {
            telemetry.Log(TelemetryEvent.PurchasePrintPageViewed, { jobId, imageId });
        }, [jobId, imageId]);
    }

    const packages: Array<IProduct> = [
        {
            productID: ProductID.PrintWalletBundle,
            name: "Wallet Photo Bundle",
            features: ["8 wallet sized prints", "Perfect for gifts", "Free shipping"],
        },
        {
            productID: ProductID.PrintFullSize,
            name: "Full Sized Print",
            features: [`Full 6" x 6" or 8" x 6" print`, "Fits inside small frames", "Free shipping"],
        },
        {
            productID: ProductID.PrintJumbo,
            name: "Jumbo Sized Print",
            features: [`Jumbo 8" x 8" or 10" x 8" print`, "Great for decoration", "Free shipping"],
        },
    ];
    return (
        <PaypalProvider>
            <ScrollablePageTemplate title={title} description={description} canonical={HeadbotRoute.PurchasePrintPage(jobId, imageId)}>
                <WithAuth>
                    <Root>
                        <H2>{t("purchasePrintPage.purchasePrint")}</H2>
                        <FullsizeImage jobId={jobId} imageId={imageId} height={200} width={200} />
                        <PackageSelector onSelectProduct={setChosenProductID} selectedProductID={chosenProductID} packages={packages} />
                        {chosenProductID !== null && <PaypalPrintPurchase productID={chosenProductID} jobId={jobId} imageId={imageId} />}
                    </Root>
                </WithAuth>
            </ScrollablePageTemplate>
        </PaypalProvider>
    );
};
